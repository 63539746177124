module.exports = {
  BASE_API_URL: process.env.BASE_API_URL,
  GATEWAY_BASE_API_URL: process.env.GATEWAY_BASE_API_URL,
  URL_API: process.env.URL_API,
  URL_SOCKET: process.env.URL_SOCKET,
  URL_DOWNLOAD: process.env.URL_DOWNLOAD,
  RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,
  TRACKING_ID: process.env.TRACKING_ID,
  BASIC_USERNAME: process.env.BASIC_USERNAME,
  BASIC_PASSWORD: process.env.BASIC_PASSWORD,
  AES_SECRET_KEY: process.env.AES_SECRET_KEY,
  AES_IV: process.env.AES_IV,
  APP_TITLE: 'Apollo',
  VAPID_KEY: process.env.VAPID_KEY,
  LAUNCHDARKLY_SDK_CLIENT_SIDE_ID: process.env.LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
  PUBLIC_BUCKET_BASE_PATH: process.env.PUBLIC_BUCKET_BASE_PATH,
  JURNAL_OAUTH_PATH: process.env.JURNAL_OAUTH_PATH
};
